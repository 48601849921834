import Vue from 'vue';

export default Vue.component(
  'street',
  {
    props: ['errorBehavior', 'isDisabled'],
    render() {
      return (
        <FormulateInput
          name="street"
          label="Улица"
          validation-name="Улица"
          type="text"
          placeholder="*Улица"
          validation="required"
          error-behavior={this.errorBehavior || 'blur'}
          disabled={this.isDisabled}
        />
      );
    },
  },
);
