import Vue from 'vue';

export default Vue.component(
  'message',
  {
    props: ['errorBehavior', 'isDisabled'],
    render() {
      return (
        <FormulateInput
          name="message"
          validation-name="Сообщение"
          type="textarea"
          label="Сообщение"
          validation="required"
          validation-messages={{
            required: 'Напишите сообщение.',
          }}
          error-behavior={this.errorBehavior || 'blur'}
        />
      );
    },
  },
);
