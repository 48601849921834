import Vue from 'vue';
import cx from 'classnames';
import has from 'lodash.has';
import values from 'lodash.values';
import VueFormulate from '@braid/vue-formulate';
import { ru } from '@braid/vue-formulate-i18n';
import { Fragment } from 'vue-fragment';
import inputs from '@/components/ui/formulateInputs';
import MaskedPhone from '@/components/ui/customInputs/_maskedPhoneInput';
import MainButton from '@/components/ui/mainButton';
import PopupContainer from '@/components/ui/popupContainer';
import { getFormatPhoneNumber } from '@/utils';
import { getRequest } from '@/utils/api';

import './auth.scss';

Vue.use(VueFormulate, {
  plugins: [ru],
  locale: 'ru',
  library: {
    maskedPhone: {
      component: MaskedPhone,
      slotProps: {
        component: ['disabled'],
      },
    },
  },
});

const initialCounterValue = 59;

const AuthForm = Vue.component(
  'auth-form',
  {
    props: ['isActive', 'closeForm'],
    components: {
      Fragment,
      MainButton,
      PopupContainer,
    },
    data() {
      return {
        formState: 'auth',
        requestState: 'none',
        errors: {},
        sendedNum: null,
        counter: initialCounterValue,
        interval: null,
      };
    },
    destroyed() {
      window.location.hash = '';
    },
    methods: {
      handleChangeFormState(type) {
        this.formState = type;
        clearInterval(this.interval);
        this.interval = null;
        this.counter = initialCounterValue;
      },
      handleClosePopup() {
        this.formState = 'auth';
        this.sendedNum = null;
        this.closeForm();
      },
      checkValidation(type) {
        const self = this;

        function setState(submission) {
          self.errors = {
            ...self.errors,
            [type]: {
              ...self.errors[type],
              [submission.name]: submission.hasErrors,
            },
          };
        }

        return setState;
      },
      async handleSendPhone({ phone }) {
        const self = this;

        this.sendedNum = phone;
        this.requestState = 'requested';

        try {
          const params = {
            action: 'sendCode',
            phone,
          };

          const { data: { status, data, message } } = await getRequest('/ajax/userHandler.php', params);

          this.requestState = 'success';

          if (status === 'success') {
            this.formState = 'smsCode';

            this.interval = setInterval(() => {
              if (self.counter > 0) {
                self.counter -= 1;
              } else {
                clearInterval(self.interval);
                self.interval = null;
                self.counter = initialCounterValue;
              }
            }, 1000);
          } else {
            this.setMessage(message, 200);

            if (has(data, 'time_left')) {
              this.formState = 'smsCode';
              this.counter = data.time_left;
              this.interval = setInterval(() => {
                if (self.counter > 0) {
                  self.counter -= 1;
                } else {
                  clearInterval(self.interval);
                  self.interval = null;
                  self.counter = initialCounterValue;
                }
              }, 1000);
            }

            this.requestState = 'failed';
          }
        } catch (e) {
          this.requestState = 'failed';
        }
      },
      async handleSendCode({ code }) {
        this.requestState = 'requested';

        try {
          const params = { action: 'checkCode', code };
          const { data: { status, data, message } } = await getRequest('/ajax/userHandler.php', params);

          if (status === 'success') {
            this.closeForm();

            this.requestState = 'success';

            window.location.hash = '';
            window.location.reload();
          } else {
            this.setMessage(message, 200);

            if (has(data, 'time_left')) {
              this.formState = 'auth';
            }

            this.requestState = 'failed';
          }
        } catch (e) {
          this.requestState = 'failed';
        }
      },
      setMessage(message = 'Произошла ошибка', status = 404) {
        messenger.setMessage({ // global class
          type: 'error',
          message,
          status,
        });
      },
    },
    render() {
      const hasErrors = values(this.errors[this.formState]).some((isError) => isError);
      const isRequested = this.requestState === 'requested';

      const authForm = (
        <FormulateForm
          name="auth"
          class="auth-form"
          onSubmit={this.handleSendPhone}
          onValidation={this.checkValidation('auth')}
          key="auth"
        >
          <h2 class="form-title">Вход и регистрация</h2>
          <p class="form-text">Мы отправим на номер SMS-сообщение с кодом подтверждения</p>
          <fieldset class="form__fieldset">
            <div class="fieldset-contaner">
              {
                inputs.phone()
              }
            </div>
          </fieldset>
          <button
            type="submit"
            class={cx({
              'auth-btn': true,
              'auth-btn_disabled': hasErrors,
              requested: isRequested,
            })}
            disabled={hasErrors || isRequested}
          >
            <MainButton>
              Получить код
            </MainButton>
          </button>
        </FormulateForm>
      );

      const smsCodeForm = (
        <FormulateForm
          name="smsCode"
          class="auth-form"
          onSubmit={this.handleSendCode}
          onValidation={this.checkValidation('smsCode')}
          key="smsCode"
        >
          <h2 class="form-title">Введите код</h2>
          <p class="form-text">
            Мы отправили его на номер
            <br />
            { getFormatPhoneNumber(`+7${this.sendedNum}`) }
          </p>
          <fieldset class="form__fieldset">
            <div class="fieldset-contaner">
              {
                inputs.code()
              }
            </div>
          </fieldset>
          <button
            class={cx({
              'auth-btn': true,
              'auth-btn_disabled': hasErrors,
              requested: isRequested,
            })}
            disabled={hasErrors || isRequested}
            type="submit"
          >
            <MainButton>
              Подтвердить телефон
            </MainButton>
          </button>
          {
            this.interval ? (
              <div class="code-counter">
                Отправить код повторно через&nbsp;
                <span>00:</span>
                <span>{ String(this.counter).padStart(2, '0') }</span>
              </div>
            ) : (
              <button
                class="underline-btn code-counter"
                onClick={() => this.handleSendPhone({ phone: this.sendedNum }) }
                type="button"
              >
                Отправить код повтоно
              </button>
            )
          }
          <button
            class={
              cx({
                'underline-btn': true,
                requested: isRequested,
              })
            }
            onClick={() => this.handleChangeFormState('auth')}
            type="button"
          >
            Изменить номер
          </button>
        </FormulateForm>
      );

      const forms = {
        auth: authForm,
        smsCode: smsCodeForm,
      };

      return (
        <PopupContainer>
          <div class="auth">
            <div class="close" onClick={this.handleClosePopup} />
            <div class="auth__container">
              <transition name="fade" mode="out-in">
                {
                  forms[this.formState]
                }
              </transition>
            </div>
          </div>
        </PopupContainer>
      );
    },
  },
);

export default AuthForm;
