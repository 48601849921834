import Vue from 'vue';
import cx from 'classnames';
import VTooltip from 'v-tooltip';
import MainButton from '@/components/ui/mainButton';
import { postRequest } from '@/utils/api';

Vue.use(VTooltip);

const logout = Vue.component(
  'logout',
  {
    components: {
      MainButton,
    },
    data() {
      return {
        requestState: 'none',
        isOpen: false,
      };
    },
    methods: {
      handleOpenTooltip() {
        this.isOpen = true;
      },
      handleCloseTooltip() {
        this.isOpen = false;
      },
      async handleLogout() {
        this.requestState = 'requested';

        try {
          const {
            data: {
              status,
              message,
            },
          } = await postRequest('/ajax/userHandler.php', { action: 'userLogout' });

          if (status === 'success') {
            window.location.reload();

            this.requestState = 'success';
          } else {
            this.setError(message, 200);

            this.requestState = 'failed';
          }
        } catch (e) {
          this.requestState = 'failed';
        }
      },
      setError(message = 'Произошла ошибка', status = 404) {
        errorProcessing.setError({ // global class
          type: 'error',
          message,
          status,
        });
      },
    },
    render() {
      const isRequested = this.requestState === 'requested';

      const classNames = cx({
        logout: true,
        requested: isRequested,
      });

      const links = [
        {
          url: '/personal/private/',
          name: 'Профиль',
        },
        {
          url: '/about/feedback/',
          name: 'Обратная связь',
        },
        {
          url: '/personal/orders/',
          name: 'Мои заказы',
        },
      ];

      return (
        <div class={classNames}>
          <v-popover
            offset="10"
            placement="bottom"
            delay="100"
            autohide
            shown={this.isOpen}
            onShow={this.handleOpenTooltip}
            onHide={this.handleCloseTooltip}
          >
              <div
                class={
                  cx({
                    icons__icon: true,
                    icons__icon_personal: true,
                    icons__icon_personal_auth: true,
                    icons__icon_personal_auth_open: this.isOpen,
                  })
                }
              />
              <template class="tooltip__content" slot="popover" id="headerClass">
                  <div class="auth-user-nav">
                      <ul>
                        {
                          links.map(({ name, url }) => (
                            <li key={url}>
                              <a
                                href={url}
                                class={
                                  cx({
                                    active_link: window.location.pathname === url,
                                  })
                                }
                              >
                                { name }
                              </a>
                            </li>
                          ))
                        }
                      </ul>

                    <v-popover
                      offset="10"
                      placement="top"
                      delay="50"
                      autoHide
                    >
                      <MainButton>
                        Выйти
                      </MainButton>

                      <template slot="popover" class="tooltip__content">
                        <div class="tooltip__question">
                          Уверены, что хотите выйти?
                        </div>
                        <div class="tooltip__btns">
                          <button v-close-popover onClick={this.handleLogout}>
                            <MainButton>
                              Выйти
                            </MainButton>
                          </button>
                          <button v-close-popover>
                            <MainButton>
                              Отменить
                            </MainButton>
                          </button>
                        </div>
                      </template>
                    </v-popover>
                  </div>
              </template>
          </v-popover>
        </div>
      );
    },
  },
);

export default logout;
