import Vue from 'vue';
import cx from 'classnames';
import BasketElement from './basketElement';

const basketElements = Vue.component(
  'basket-elements',
  {
    functional: true,
    components: {
      BasketElement,
    },
    render: (h, ctx) => {
      const {
        props: {
          elements,
          changeQuantity,
          removeElement,
          requestState,
          isAuth,
          disabledUpBtnIds,
        },
      } = ctx;

      const isRequested = requestState === 'requested';

      const basketItemsClasses = cx({
        'basket-items': true,
        requested: isRequested,
      });

      return (
        <div class={basketItemsClasses}>
          {
            elements.map((data) => {
              const isDisabledUpBtn = disabledUpBtnIds.includes(data.id);

              return (
                <BasketElement
                  key={data.id}
                  data={data}
                  change-quantity={changeQuantity}
                  remove-element={removeElement}
                  request-state={requestState}
                  is-auth={isAuth}
                  is-disabled-up-btn={isDisabledUpBtn}
                />
              );
            })
          }
        </div>
      );
    },
  },
);

export default basketElements;
