import axios from 'axios';

const baseURL = window.location.origin;

const instance = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const updatedHeaders = {
      'X-Requested-With': 'XMLHttpRequest',
    };

    return { ...config, headers: { ...config.headers, ...updatedHeaders } };
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => {
    const { hasError } = messenger.getMessageData();
    if (hasError) {
      messenger.reset();
    }
    return response;
  },
  (error) => {
    const { response: { status, data: { message } } } = error;
    const customMessage = 'Неизвестная ошибка. Попробуйте выполнить действие позже.';
    messenger.setMessage({
      type: 'error',
      message: message || customMessage,
      status,
    });

    Promise.reject(error);
  },
);

export const postRequest = async (url, data = {}, params = {}, headers = {}) => {
  const config = {
    headers,
    params,
    validateStatus(status) {
      return messenger.constructor.isStatusValid(status);
    },
  };

  const res = await instance.post(url, data, config);

  return res;
};

export const getRequest = async (url, params = {}) => {
  const config = {
    url,
    params,
    validateStatus(status) {
      return messenger.constructor.isStatusValid(status);
    },
  };

  const res = await instance.get(url, config);

  return res;
};
