import Vue from 'vue';
import VTooltip from 'v-tooltip';
import cx from 'classnames';
import { getFormattedCost, smallTooltip } from '@/utils';
import removeIcon from '@/assets/imgs/basket/remove.svg';
import MainButton from '@/components/ui/mainButton';

Vue.use(VTooltip);

const basketElement = Vue.component(
  'basket-elements',
  {
    components: {
      MainButton,
    },
    functional: true,
    render: (h, ctx) => {
      const {
        props: {
          data: {
            id,
            pageUrl,
            quantity,
            quantity_limit,
            // basePriceTotal,
            discountPriceTotal,
            name,
            sectionName,
            picture,
            colorName,
            colorCode,
            colorFile,
          },
          changeQuantity,
          removeElement,
          requestState,
          isDisabledUpBtn,
        },
      } = ctx;

      const handleChangeQuantity = (action) => ({ target: { value } }) => {
        const guardExp = (action === 'decrement' && quantity === 1);

        if (guardExp) {
          return;
        }

        const getNumOfGoods = (n) => {
          if (n >= quantity_limit) {
            return quantity_limit;
          } return n <= 1 ? 1 : n;
        };

        const actions = {
          increment: () => changeQuantity(id, quantity + 1),
          decrement: () => changeQuantity(id, quantity - 1),
          change: () => changeQuantity(id, getNumOfGoods(Number(value))),
        };

        actions[action]();
      };

      // const isEqualPrices = basePriceTotal === discountPriceTotal;

      const isRequested = requestState === 'requested';
      const isDisabledDecrementBtn = Number(quantity) === 1;
      const isDisabledIncrementCount = Number(quantity) === Number(quantity_limit);

      const btns = (
        <div class="basket-info__item basket-info__item_btns">
          <div class="quantity-btns">
            <button
              class={
                cx({
                  'quantity-btns__decrement': true,
                  disabled: isDisabledDecrementBtn,
                })
              }
              onClick={handleChangeQuantity('decrement')}
              disabled={isRequested || isDisabledDecrementBtn}
            >
              &ndash;
            </button>
            <input
              class="quantity-btns__count"
              value={quantity}
              type="number"
              onChange={handleChangeQuantity('change')}
              max="999"
              min="1"
              step="1"
            />
            <button
              class={
                cx({
                  'quantity-btns__increment': true,
                  disabled: isDisabledUpBtn || isDisabledIncrementCount,
                })
              }
              onClick={handleChangeQuantity('increment')}
              disabled={isRequested || isDisabledUpBtn || isDisabledIncrementCount}
            >
              +
            </button>
          </div>
        </div>
      );

      // const itemFullPrice = getFormattedCost(basePriceTotal);
      const itemDiscountPrice = getFormattedCost(discountPriceTotal);

      const hasColor = colorName || colorCode;

      return (
        <div id={id} class="basket-items__element basket-item">
          <div class="basket-item__top">
            <a
              href={pageUrl}
              class="basket-item__top__img"
              target="_blank"
            >
              <img src={picture} alt="" />
            </a>
            { btns }
          </div>
          <div class="basket-item__info basket-info">
            <a
              href={pageUrl}
              class="basket-info__item basket-info__item_name"
              target="_blank"
            >
              { name }
            </a>
            <div class="basket-info__item basket-info__item_offer">{ sectionName }</div>
            {
              hasColor && (
                <div
                  class="basket-info__item basket-info__item_color"
                  v-tooltip={smallTooltip(colorName) }
                >
                  <div
                    style={{
                      backgroundColor: !colorFile ? colorCode : '#ffffff',
                      backgroundImage: colorFile ? `url(${colorFile})` : 'none',
                    }}
                  />
                  <span>
                    { colorName }
                  </span>
                </div>
              )
            }
            { btns }
          </div>
          <div class="basket-item__left">
            <v-popover
              offset="5"
              placement="left"
              delay="100"
              autoHide
            >
              <button
                class="remove-btn"
                disabled={isRequested}
              >
                <img src={removeIcon} alt="" />
              </button>

              <template slot="popover" class="tooltip__content">
                <div class="tooltip__question">
                  Удалить из корзины?
                </div>
                <div class="tooltip__btns">
                  <button v-close-popover onClick={() => removeElement(id)}>
                    <MainButton>
                      Удалить
                    </MainButton>
                  </button>
                  <button v-close-popover>
                    <MainButton>
                      Отменить
                    </MainButton>
                  </button>
                </div>
              </template>
            </v-popover>
            <div class="basket-item__price">
              {/* {
                !isEqualPrices && (
                  <s class="basket-item__price_full">{ itemFullPrice }&nbsp;&#x20bd;</s>
                )
              } */}
              <span class="basket-item__price_discount">{ itemDiscountPrice }&nbsp;&#x20bd;</span>
            </div>
          </div>
        </div>
      );
    },
  },
);

export default basketElement;
