import Vue from 'vue';
import cx from 'classnames';
import has from 'lodash.has';

export default Vue.component(
  'main-btn',
  {
    functional: true,
    render: (h, ctx) => {
      const {
        slots,
        data: {
          attrs,
        },
      } = ctx;
      const [child] = slots().default || [{}];

      const elClassName = cx({
        'main-button': true,
        [has(attrs, 'className') ? `main-button_${attrs.className}` : '']: true,
      });

      return (
        <span class={elClassName} key="main-button">
          <span>{ child.tag ? child : child.text }</span>
          <div class="main-button__line main-button__line_top" />
          <div class="main-button__line main-button__line_right" />
          <div class="main-button__line main-button__line_bottom" />
          <div class="main-button__line main-button__line_left" />
        </span>
      );
    },
  },
);
