import Vue from 'vue';
import { name } from '@/utils/regExps';

export default Vue.component(
  'name',
  {
    props: ['errorBehavior', 'isDisabled', 'name'],
    render() {
      return (
        <FormulateInput
          name={this.name || 'name'}
          validation-name="Имя и фамилия"
          type="text"
          label="Имя Фамилия"
          placeholder="Введите имя и фамилию"
          validation="required|ru"
          validation-rules={{
            ru: ({ value }) => name.test(value),
          }}
          validation-messages={{
            ru: 'Имя и фамилия могут содеражть только русские буквы.',
          }}
          error-behavior={this.errorBehavior || 'blur'}
          disabled={this.isDisabled}
        />
      );
    },
  },
);
