import Vue from 'vue';
import { getFormattedCost } from '@/utils';
import BasketElements from '../basketElements';
import MainButton from '@/components/ui/mainButton';
import Loader from '@/components/ui/loader';

const miniBasket = Vue.component(
  'mini-basket',
  {
    functional: true,
    components: {
      BasketElements,
      MainButton,
      Loader,
    },
    render: (h, ctx) => {
      const {
        props: {
          elements,
          totalPrice,
          changeQuantity,
          removeElement,
          requestState,
          isAuth,
          disabledUpBtnIds,
          close,
        },
      } = ctx;

      const isBasketDataRequesred = requestState === 'requestedBasketData';

      const isEmpty = !elements.length;

      const basket = isEmpty ? (
        <div class="basket basket_empty" key="basket">
          <div class="close" onClick={close} />
          <h2>В корзине пока ничего нет</h2>
          <p>
            Начните с главной страницы или воспользуйтеь поиском, чтобы найти что-то конкретное
          </p>
        </div>
      ) : (
        <div class="basket" key="basket">
          <div class="close" onClick={close} />
          <h2>Корзина</h2>
          <div class="basket-inner">
            <BasketElements
              elements={elements}
              request-state={requestState}
              change-quantity={changeQuantity}
              remove-element={removeElement}
              is-auth={isAuth}
              disabled-up-btn-ids={disabledUpBtnIds}
            />
          </div>
          <div class="basket-bottom">
            <div class="total">
              <span class="total__text">Сумма заказа</span>
              <span class="total__price">{ getFormattedCost(totalPrice) } &#8381;</span>
            </div>
            <a href="/personal/order/make" class="order-btn">
              <MainButton>
                Перейти к оформлению
              </MainButton>
            </a>
          </div>
        </div>
      );

      return (
        <transition name="fade" mode="out-in">
          {
            isBasketDataRequesred ? <Loader /> : basket
          }
        </transition>
      );
    },
  },
);

export default miniBasket;
