import Vue from 'vue';

export default Vue.component(
  'appartment',
  {
    functional: true,
    render: (h, ctx) => {
      const { props: { isDisabled } } = ctx;

      return (
        <FormulateInput
          name="apartment"
          validation-name="Квартира/офис"
          type="number"
          placeholder="Квартира/офис"
          disabled={isDisabled}
        />
      );
    },
  },
);
