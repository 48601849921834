export const breakpoints = {
  bigTabletWidth: 1024,
  tabletWidth: 960,
  mobileWidth: 568,
};

export const isPortrait = (width, height) => width <= height;
export const isLandscape = (width, height) => width > height;

export const createArr = (n) => [...Array(n)].map((_n, index) => index + 1);

export const splitToChunks = (array, parts) => createArr(parts)
  .reverse()
  .reduce((acc, el) => {
    const part = array.splice(0, Math.ceil(array.length / el));
    acc.push(part);
    return acc;
  }, [])
  .reverse();

const { bigTabletWidth, tabletWidth, mobileWidth } = breakpoints;
export const sizes = [
  {
    screenType: 'desktop',
    check: (w, h) => (w > tabletWidth && isLandscape(w, h)) || w > bigTabletWidth,
  },
  {
    screenType: 'tablet',
    check: (w) => (w > mobileWidth && w <= bigTabletWidth),
  },
  {
    screenType: 'mobile',
    check: (w) => (w <= mobileWidth),
  },
];

export const getScreenType = (w, h) => {
  const { screenType } = sizes.find(({ check }) => check(w, h));
  return screenType;
};

export const onLoad = () => new Promise(
  (resolve) => {
    if (document.readyState === 'complete') {
      resolve();
    } else {
      window.addEventListener('load', () => resolve(), { once: true });
    }
  },
);

export const getFormattedCost = (value) => {
  if (!value) {
    return;
  }

  const fexedValue = value.toFixed(1);
  if (Number(fexedValue) % 1) {
    // eslint-disable-next-line consistent-return
    return fexedValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    // eslint-disable-next-line consistent-return
  } return Math.trunc(fexedValue)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const getFormatPhoneNumber = (str) => {
  if (!str) {
    return null;
  }

  const match = str.match(/^\+7(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    return `+7 (${match[1]}) ${match[2]}-${match[3]}-${match[4]}`;
  } return null;
};

export const getFormattedPromocode = (code) => {
  if (!code) {
    return '';
  }

  const first = code.substr(0, 2);
  const second = code.substr(2, 5);
  const third = code.substr(7);

  return `${first}-${second}-${third}`;
};

export const smallTooltip = (content) => ({
  content,
  placement: 'top',
  offset: 5,
  triggers: ['click', 'hover', 'touch'],
  delay: {
    show: 500,
    hide: 300,
  },
});

export const tooltipSubmitBtn = (content) => ({
  content,
  placement: 'top',
  offset: 10,
  delay: {
    show: 500,
    hide: 300,
  },
});
