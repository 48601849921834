/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Name from './Name';
import Email from './Email';
import Phone from './Phone';
import Date from './Date';
import Autocomplite from './Autocomplite';
import Select from './Select';
import Street from './Street';
import Building from './Building';
import Apartment from './Apartment';
import Message from './Message';
import Pass from './Pass';
import Code from './Code';
import Promocode from './Promocode';

class Inputs extends Vue {
  name(props) {
    return <Name { ...{ props } } />;
  }

  email(props) {
    return <Email { ...{ props } } />;
  }

  phone(props) {
    return <Phone { ...{ props } } />;
  }

  date(props) {
    return <Date { ...{ props } } />;
  }

  autocomplite(props) {
    return <Autocomplite { ...{ props } } />;
  }

  select(props) {
    return <Select { ...{ props } } />;
  }

  street(props) {
    return <Street { ...{ props } } />;
  }

  building(props) {
    return <Building { ...{ props } } />;
  }

  apartment(props) {
    return <Apartment { ...{ props } } />;
  }

  message(props) {
    return <Message { ...{ props } } />;
  }

  pass(props) {
    return <Pass { ...{ props } } />;
  }

  promocode(props) {
    return <Promocode { ...{ props } } />;
  }

  code(props) {
    return <Code { ...{ props } } />;
  }
}

export default new Inputs();
