import Vue from 'vue';

export default Vue.component(
  'pass',
  {
    props: ['name', 'type', 'placeholder', 'validation', 'validationRules', 'validationName', 'validationMessages'],
    render() {
      return (
        <FormulateInput
          name={this.name}
          type="password"
          placeholder={this.placeholder}
          validation={this.validation}
          validation-rules={this.validationRules}
          validation-name={this.validationName}
          validation-messages={this.validationMessages}
        />
      );
    },
  },
);
