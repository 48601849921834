import Vue from 'vue';

export default Vue.component(
  'city',
  {
    functional: true,
    render: (h, ctx) => {
      const {
        props: {
          name,
          validationName,
          placeholder,
          validation,
          errorBehavior,
          isDisabled,
          requestData,
        },
      } = ctx;

      return (
        <FormulateInput
          name={name}
          type='customSelect'
          validation-name={validationName}
          placeholder={placeholder}
          validation={validation}
          error-behavior={errorBehavior || 'blur'}
          disabled={isDisabled}
          request-data={requestData}
        />
      );
    },
  },
);
