import Vue from 'vue';

const loader = Vue.component(
  'loader',
  {
    functional: true,
    // eslint-disable-next-line no-unused-vars
    render: (h) => (
      <div class="loader-local" key="loader">
        <div class="lds-default">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    ),
  },
);

export default loader;
