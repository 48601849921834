/* eslint-disable no-useless-escape */

export const name = /^[а-яА-Я -]+$/;

export const ruLetters = /^[а-яА-ЯёЁ\s-]+$/;

export const phone = /^(\+7)*9{1}\d{9}$/;

export const notCyrillic = /^[^а-яА-Я]+$/;

export const email = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const code = /^[A-Za-z\d\-\_ ]{3,}$/;
// export const code = /^([A-Za-z\d]){2}\-([A-Za-z\d]){5}\-([A-Za-z\d]){7}$/;
