import Vue from 'vue';

export default Vue.component(
  'code',
  {
    functional: true,
    render: (h, ctx) => {
      const { props: { errorBehavior, isDisabled } } = ctx;

      return (
        <FormulateInput
          name="code"
          validation-name="Код"
          type="number"
          placeholder="Код"
          validation="required|number|min:4,length|max:4,length"
          validation-messages={{
            required: 'Введите код из смс.',
            min: 'Количество символов должно быть равно 4.',
          }}
          error-behavior={errorBehavior || 'blur'}
          disabled={isDisabled}
        />
      );
    },
  },
);
