import Vue from 'vue';

const mainPopup = Vue.component(
  'main-popup',
  {
    functional: true,
    render: (h, ctx) => {
      const { slots } = ctx;

      return (
        <div class="popup">
          { slots().default }
        </div>
      );
    },
  },
);

export default mainPopup;
