import Vue from 'vue';
import { notCyrillic, email } from '@/utils/regExps';

export default Vue.component(
  'email',
  {
    props: ['errorBehavior', 'isDisabled', 'validation', 'name'],
    render() {
      return (
        <FormulateInput
          name={this.name || 'email'}
          validation-name="Email"
          type="email"
          label="Электронная почта"
          placeholder="_______@___.__"
          validation={this.validation}
          validation-rules={{
            customEmail: ({ value }) => (value ? email.test(value) : true),
            notCyrillic: ({ value }) => (value ? notCyrillic.test(value) : true),
          }}
          validation-messages={{
            customEmail: 'Введите корректный email.',
            notCyrillic: 'Введите корректный email.',
          }}
          error-behavior={this.errorBehavior || 'blur'}
          disabled={this.isDisabled}
        />
      );
    },
  },
);
