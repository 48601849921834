import Vue from 'vue';
import moment from 'moment';

export default Vue.component(
  'date',
  {
    props: ['errorBehavior', 'isDisabled'],
    render() {
      const today = moment().format('DD.MM.YYYY');

      return (
        <FormulateInput
          name="date"
          type="datepicker"
          validation-name="Дата"
          validation="afterToday"
          validation-rules={{
            afterToday: ({ value }) => {
              const [dV, mV, yV] = value.split('.').map((v) => Number(v));
              const [dT, mT, yT] = today.split('.').map((v) => Number(v));
              return value ? yV < yT || (yV === yT && (mV < mT || dV < dT)) : true;
            },
          }}
          validation-messages={{
            afterToday: 'Дата рождения не может быть позже сегодняшнего дня.',
          }}
          error-behavior={this.errorBehavior || 'blur'}
          disabled={this.isDisabled}
        />
      );
    },
  },
);
