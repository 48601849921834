import Vue from 'vue';
import { code } from '@/utils/regExps';

export default Vue.component(
  'promocode',
  {
    props: ['errorBehavior', 'isDisabled', 'name'],
    render() {
      return (
        <FormulateInput
          name={this.name || 'promocode'}
          validation-name="Промокод"
          placeholder="Введите промокод"
          validation="required|codeSymbols|min:3,length"
          validation-rules={{
            codeSymbols: ({ value }) => code.test(value),
          }}
          validation-messages={{
            required: 'Введите промокод.',
            length: 'Длина не может быть меньше трех симполов.',
            codeSymbols: 'Промокод может сореджать только латинские буквы, цифры и символы (-, _, )',
          }}
          type="text"
          errorBehavior={this.errorBehavior}
          disabled={this.isDisabled || false}
        />
      );
    },
  },
);
