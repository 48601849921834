import Vue from 'vue';
import { watch } from 'melanke-watchjs';
import './messenger.scss';

export default Vue.component(
  'messenger',
  {
    data() {
      return {
        messages: [],
      };
    },
    mounted() {
      this.messages = messenger.messages;

      const watcher = () => {
        const { messages } = messenger.getMessageData();
        this.messages = messages;
      };
      // eslint-disable-next-line no-unused-vars
      watch(messenger /* global class */, 'messages', watcher);
    },
    methods: {
      removeMessage(messId) {
        messenger.removeMessage(messId);
      },
    },
    render() {
      const titles = {
        success: 'Успешно',
        warn: 'Внимание',
        error: 'Ошибка',
      };

      return (
        <div class={`messenger messenger_${messenger.position}`}>
          <transition-group name="message-list" tag="ul">
            {
              this.messages.map(({
                id,
                type,
                title,
                message,
              }) => (
                <li key={id} class={`messenger__message messenger__message_${type} message-list-item`}>
                  <h2>{title || titles[type]}</h2>
                  <p>
                    { message || 'Произошла ошибка' }
                  </p>
                  <div class="close" onClick={() => this.removeMessage(id)} />
                </li>
              ))
            }
          </transition-group>
        </div>
      );
    },
  },
);
