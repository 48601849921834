import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { watch } from 'melanke-watchjs';
import { getScreenType, isPortrait, isLandscape } from '@/utils';
import hideLoader from '@/utils/hideLoader';
import authForm from '@/components/auth';
import Messenger from '@/components/messenger';
import AuthMenu from './AuthMenu.js';
import Basket from '@/components/basket';
import PopupContainer from '@/components/ui/popupContainer';
import MainButton from '@/components/ui/mainButton';

Vue.use(VueCookies);

const isMainPage = window.location.pathname === '/';
const hashToAuth = '#need_auth=Y';
const isAuth = $cookies.get('BITRIX_SM_USER_AUTH') === 'Y';

export default () => {
  new Vue({
    el: '#header',
    components: {
      Messenger,
      authForm,
      Basket,
      PopupContainer,
      MainButton,
      AuthMenu,
    },
    data() {
      return {
        isActiveSearch: false,
        isActiveMenuBurger: false,
        activeMenuCategory: null,
        activeBurgerMenuCategories: {
          main: [],
          mode: getScreenType(window.innerWidth, window.innerHeight),
        },
        hasMessage: false,
        isActiveAuthForm: false,
        isActiveBasket: false,
      };
    },
    mounted() {
      if (!isMainPage) {
        hideLoader();
      }

      this.resizer();
      this.resizeScreen();

      const self = this;
      const watcher = () => {
        const { hasMessage } = messenger.getMessageData(); // global class
        self.hasMessage = hasMessage;
      };
      // eslint-disable-next-line no-unused-vars
      watch(messenger /* global class */, 'hasMessage', watcher);

      if (window.location.hash === hashToAuth && !isAuth) {
        this.setActiveAuthForm();
      }
    },
    computed: {
      burgerMenuTitleClass() {
        function titleClass(id) {
          return this.activeBurgerMenuCategories.main.includes(id) ? 'columns__column__title_active' : null;
        }

        return titleClass;
      },
      isMobCategoryActive() {
        function isActive(id) {
          return this.activeBurgerMenuCategories.mode === 'desktop' || this.activeBurgerMenuCategories.main.includes(id);
        }

        return isActive;
      },
    },
    methods: {
      resizer() {
        const w = window.innerWidth;
        const h = window.innerHeight;
        const screenType = getScreenType(w, h);

        if ((screenType !== 'mobile' || isLandscape(w, h)) && this.activeBurgerMenuCategories.mode !== 'desktop') {
          this.activeBurgerMenuCategories.mode = 'desktop';
          this.activeBurgerMenuCategories.main = [];
          document.body.classList.remove('body-hidden');
        } if (screenType === 'mobile' && isPortrait(w, h) && this.activeBurgerMenuCategories.mode !== 'mobile') {
          this.activeBurgerMenuCategories.mode = 'mobile';
        }
      },
      resizeScreen() {
        const cb = this.resizer;
        window.addEventListener('resize', cb);
      },
      setActiveSearch() {
        this.isActiveSearch = !this.isActiveSearch;
        document.body.classList.toggle('body-hidden');

        if (this.isActiveSearch) {
          setTimeout(() => {
            this.$refs.headerSearchInput.focus();
          }, 300);
        }

        if (this.isActiveMenuBurger) {
          this.setActivMenuBurger();
        }
      },
      setActivMenuBurger() {
        const screenType = getScreenType(window.innerWidth, window.innerHeight);

        this.isActiveMenuBurger = !this.isActiveMenuBurger;

        if (screenType === 'mobile') {
          document.body.classList.toggle('body-hidden');
        }

        this.closePopup('isActiveMenuBurger', '.content', '.header');
      },
      setActiveBurgerMenuCategories(id) {
        if (this.activeBurgerMenuCategories.mode === 'mobile') {
          if (this.activeBurgerMenuCategories.main?.includes(id)) {
            this.activeBurgerMenuCategories.main = this.activeBurgerMenuCategories.main.filter(
              (el) => el !== id,
            );
          } else {
            this.activeBurgerMenuCategories.main = [...this.activeBurgerMenuCategories.main, id];
          }
        }
      },
      setActiveAuthForm() {
        this.isActiveAuthForm = !this.isActiveAuthForm;
        document.body.classList.toggle('body-hidden');

        this.isActiveMenuBurger = false;
      },
      setActiveBasket() {
        const screenType = getScreenType(window.innerWidth, window.innerHeight);

        this.isActiveBasket = !this.isActiveBasket;
        this.closePopup('isActiveBasket', '.popup', '.basket');

        messenger.reset();

        if (screenType === 'mobile') {
          document.body.classList.toggle('body-hidden');
        }

        this.isActiveMenuBurger = false;
      },
      closePopup(state, selectorToClose, el) {
        const self = this;

        const cb = ({ target }) => {
          if (!target.closest(el)) {
            self[state] = false;
            document.body.classList.remove('body-hidden');
            $(document).off('click', `#app ${selectorToClose}`, cb);
          }
        };
        $(document).on('click', `#app ${selectorToClose}`, cb);
      },
    },
  });
};
