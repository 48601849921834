import Vue from 'vue';
import { phone } from '@/utils/regExps';

export default Vue.component(
  'phone',
  {
    props: ['errorBehavior', 'isDisabled', 'name'],
    render() {
      return (
        <FormulateInput
          name={this.name || 'phone'}
          validation-name="Телефон"
          type="maskedPhone"
          label="Телефон"
          validation="required|ruPhone"
          validation-rules={{
            ruPhone: ({ value }) => phone.test(value),
          }}
          validation-messages={{
            ruPhone: 'Введите корректный номер телефона.',
          }}
          error-behavior={this.errorBehavior || 'blur'}
          disabled={this.isDisabled || false}
        />
      );
    },
  },
);
