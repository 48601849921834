import Vue from 'vue';

export default Vue.component(
  'select',
  {
    props: [
      'name',
      'label',
      'value',
      'placeholder',
      'options',
      'validation',
      'validationName',
      'errorBehavior',
      'isDisabled',
      'value',
      'screenType',
      'onChangeValue',
    ],
    data() {
      return {
        selectedValue: this.value,
      };
    },
    methods: {
      changeValue(value) {
        this.selectedValue = value;

        if (this.onChangeValue) {
          this.onChangeValue(value);
        }
      },
    },
    render() {
      return (
        <FormulateInput
          v-model={this.selectedValue}
          onInput={this.changeValue}
          name={this.name}
          label={this.label}
          options={this.options}
          type={this.screenType === 'desktop' ? 'vue-select' : 'select'}
          validation-name={this.validationName}
          placeholder={this.placeholder}
          validation={this.validation}
          error-behavior={this.errorBehavior || 'blur'}
          disabled={this.isDisabled}
        />
      );
    },
  },
);
