import Vue from 'vue';

export default Vue.component(
  'house',
  {
    props: ['errorBehavior', 'isDisabled'],
    render() {
      return (
        <FormulateInput
          name="building"
          label="Дом"
          validation-name="Дом"
          type="text"
          placeholder="*Дом"
          validation="required"
          error-behavior={this.errorBehavior || 'blur'}
          disabled={this.isDisabled}
        />
      );
    },
  },
);
