import Vue from 'vue';
import cx from 'classnames';
import { getFormattedCost } from '@/utils';
import BasketElements from '../basketElements';
import MainButton from '@/components/ui/mainButton';
import Loader from '@/components/ui/loader';

const orderBasket = Vue.component(
  'order-basket',
  {
    functional: true,
    components: {
      BasketElements,
      MainButton,
      Loader,
    },
    render: (h, ctx) => {
      const {
        props: {
          elements,
          basePrice,
          totalPrice,
          changeQuantity,
          removeElement,
          requestState,
          disabledUpBtnIds,
        },
        parent: {
          $store: {
            getters: {
              DELIVERY_PRICE,
            },
          },
        },
      } = ctx;

      const [deliveryBasePrice, deliveryDiscountPrice] = DELIVERY_PRICE;

      const goods = () => {
        const l = elements.length;
        if (l === 1) {
          return 'товар';
        } if (l > 1 && l < 5) {
          return 'товара';
        } return 'товаров';
      };

      const isBasketDataRequesred = requestState === 'requestedBasketData';
      const isBasketDataRecalculateRequesred = requestState === 'recalculaterequestedBasketData';

      const isEmpty = !elements.length;

      const containerClassNames = cx({
        'basket-section-order__container': true,
        requested: isBasketDataRecalculateRequesred,
      });

      const hasDelivery = deliveryBasePrice > 0;
      const hasDiscount = basePrice - totalPrice + deliveryDiscountPrice > 0;

      const finalDiscountPrice = basePrice - totalPrice + deliveryDiscountPrice;
      const finalPrice = totalPrice + deliveryBasePrice;

      const basket = isEmpty ? (
        <div key="basket">
          <h3 class="basket-elements-count">
            Корзина пуста
          </h3>
        </div>
      ) : (
        <div key="basket">
          <h3 class="basket-elements-count">
            В заказе
            { ' ' }
            { elements.length }
            { ' ' }
            { goods() }
          </h3>
          <div class={containerClassNames}>
            <BasketElements
              elements={elements}
              change-quantity={changeQuantity}
              remove-element={removeElement}
              request-state={requestState}
              disabled-up-btn-ids={disabledUpBtnIds}
            />
            <ul class="total">
              <li>
                <span class="total__text">Сумма</span>
                <span class="total__price">{ getFormattedCost(totalPrice) } &#8381;</span>
              </li>
              <transition name="fade">
                {
                  hasDelivery && (
                    <li>
                      <span class="total__text">Доставка</span>
                      <span class="total__price">{ getFormattedCost(deliveryBasePrice) } &#8381;</span>
                    </li>
                  )
                }
              </transition>
              <transition name="fade">
                {
                  hasDiscount && (
                    <li>
                      <span class="total__text">Скидка</span>
                      <span class="total__price">{ getFormattedCost(finalDiscountPrice) } &#8381;</span>
                    </li>
                  )
                }
              </transition>
              <li>
                <span class="total__text total__text_sum">Сумма заказа</span>
                <span class="total__price">{ getFormattedCost(finalPrice) } &#8381;</span>
              </li>
            </ul>
          </div>
        </div>
      );

      return (
        <div class="basket-section-order">
          <transition name="fade" mode="out-in">
            {
              isBasketDataRequesred ? <Loader /> : basket
            }
          </transition>
        </div>
      );
    },
  },
);

export default orderBasket;
