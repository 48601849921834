import Vue from 'vue';
import { TheMask } from 'vue-the-mask';

export default Vue.component(
  'masked-phone',
  {
    components: {
      TheMask,
    },
    props: {
      context: {
        type: Object,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        customTokens: {
          '#': { pattern: /\d/ },
        },
      };
    },
    render() {
      return (
        <div
          data-type={this.context.type}
          class="formulate-input-element formulate-input-element--phone"
        >
          <the-mask
            mask={['+7 (###) ###-##-##']}
            tokens={this.customTokens}
            type="tel"
            placeholder="+7 (___)___-__-__"
            v-bind={this.context.attributes}
            v-model={this.context.model}
            disabled={this.disabled}
            vOn:blur_native={this.context.blurHandler}
          />
        </div>
      );
    },
  },
);
