import Vue from 'vue';
import MiniBasketLayout from './basket-layouts/mini-basket';
import OrderBasketLayout from './basket-layouts/order-basket';
import MainButton from '@/components/ui/mainButton';
import { postRequest, getRequest } from '@/utils/api';

import './basket.scss';

const basket = Vue.component(
  'basket',
  {
    components: {
      MiniBasketLayout,
      OrderBasketLayout,
      MainButton,
    },
    props: [
      'basketType',
      'data',
      'close',
    ],
    data() {
      return {
        type: this.basketType || 'mini',
        requestState: 'none',
        basketItems: this.data ? this?.data?.data?.items : [],
        totalPrice: 0,
        basePrice: 0,
        isAuth: false,
        disabledUpBtnIds: [],
      };
    },
    mounted() {
      this.getBasketData();
    },
    watch: {
      PROMOCODE_STATE(state, oldState) {
        const canRecalculate = state === 'sent' || (state === 'active' && oldState === 'sent');

        if (canRecalculate) {
          this.getBasketData('recalculate');
        }
      },
    },
    computed: {
      PROMOCODE_STATE() {
        return this.$store?.getters?.PROMOCODE_STATE;
      },
    },
    methods: {
      getEndingOfTheWord() {
        const l = this.data.length;

        if (l === 1) {
          return 'товар';
        } if (l > 1 && l < 5) {
          return 'товара';
        } return 'товаров';
      },
      async getBasketData(type = '') {
        this.requestState = `${type}requestedBasketData`;

        const isOrderPage = this.type === 'order';

        try {
          const params = {
            action: 'getBasket',
            order: isOrderPage,
          };

          const {
            data: {
              status,
              message,
              data: {
                items,
                baseTotalSum,
                discountTotalSum,
              },
            },
          } = await getRequest('/ajax/saleHandler.php', params);

          if (status === 'success') {
            this.basketItems = items;
            this.basePrice = baseTotalSum;
            this.totalPrice = discountTotalSum;

            this.requestState = 'success';

            if (setBasketCount) { // global func
              setBasketCount(items.length);
            }
          } else {
            this.requestState = 'failed';
            this.setMessage(message);
          }
        } catch (e) {
          this.requestState = 'failed';
        }
      },
      async changeQuantity(id, n) {
        this.requestState = 'requested';

        try {
          const data = {
            action: 'upBasketItem',
            item_id: id,
            item_quantity: n,
          };

          const {
            data: {
              status,
              message,
              is_limit,
              data: {
                items,
                discountTotalSum,
                baseTotalSum,
              },
            },
          } = await postRequest('/ajax/saleHandler.php', data);

          if (status === 'success') {
            this.requestState = 'success';

            this.basketItems = items;
            this.basePrice = baseTotalSum;
            this.totalPrice = discountTotalSum;

            const isDisabledCurrentUpBtn = this.disabledUpBtnIds.includes(id);

            if (is_limit && !isDisabledCurrentUpBtn) {
              this.disabledUpBtnIds = [...this.disabledUpBtnIds, id];
              this.setMessage(message, 200, 'warn');
            } else {
              this.disabledUpBtnIds = this.disabledUpBtnIds.filter((elId) => elId !== id);
            }
          } else {
            this.requestState = 'failed';
            this.setMessage(message);
          }
        } catch (e) {
          this.requestState = 'failed';
        }
      },
      async remove(id) {
        this.requestState = 'requested';

        try {
          const data = {
            action: 'delFromBasket',
            item_id: id,
          };

          const {
            data: {
              status,
              message,
              data: { items, discountTotalSum },
            },
          } = await postRequest('/ajax/saleHandler.php', data);

          if (status === 'success') {
            this.basketItems = items;
            this.totalPrice = discountTotalSum;

            if (setBasketCount) { // global func
              setBasketCount(items.length);
            }

            if (!items.length) {
              this.basketItems = [];
              this.totalPrice = 0;

              const timeoutBeforeReload = 1000;

              const actions = {
                order: () => {
                  setTimeout(() => {
                    window.location.href = '/';
                  }, timeoutBeforeReload);
                },
                mini: () => {
                  this.close();

                  setTimeout(() => {
                    window.location.reload();
                  }, timeoutBeforeReload);
                },
              };

              actions[this.type]();
            }

            this.requestState = 'success';
          } else {
            this.requestState = 'failed';

            this.setMessage(message, 200);
          }
        } catch (e) {
          this.requestState = 'failed';
        }
      },
      setMessage(message = 'Произошла ошибка', status = 404, type = 'error') {
        const position = this.type === 'mini' ? 'top-left' : 'top-right';
        messenger.setMessage({ // global class
          type,
          message,
          status,
          position,
        });
      },
    },
    render() {
      const layouts = {
        mini: (props) => <MiniBasketLayout { ...{ props } } />,
        order: (props) => <OrderBasketLayout { ...{ props } } />,
      };

      const props = {
        elements: this.basketItems,
        'base-price': this.basePrice,
        'total-price': this.totalPrice,
        'request-state': this.requestState,
        'change-quantity': this.changeQuantity,
        'remove-element': this.remove,
        'is-auth': this.isAuth,
        'disabled-up-btn-ids': this.disabledUpBtnIds,
        close: this.close,
      };

      return layouts[this.type](props);
    },
  },
);

export default basket;
